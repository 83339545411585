.main {
  text-align: center;
  padding-bottom: 30px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 75px;
  margin-bottom: 0;
  margin-top: 0;
}

.title:after {
  content: "";
  display: block;
  width: 1px;
  height: 60px;
  background-color: #fff;
  margin: 26px auto auto;
}

.video-container {
  max-width: 1230px;
  margin: 0 auto;
}

.video {
  max-width: 100%;
}

@media (max-width: 576px) {
  .main {
    padding-top: 50px;
  }
}
