.calendar {
  margin-top: 30px;
}

.calendar-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.calendar-item {
  margin: 15px;
}

/* launches */

.launches {
  width: 360px;
  height: 100%;
  border: 1px white solid;
  border-radius: 30px;
  background-color: #111111;
  overflow: hidden;
  padding: 30px;
  text-align: left;
}

.launches-date {
  font-size: 14px;
  margin-bottom: 15px;
  display: block;
}

.launches-image {
  position: relative;
}

.launches-content {
  overflow: hidden;
}

.launches-image img {
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.launches-title {
  margin-top: 25px;
  margin-bottom: 25px;
}

.launches-details {
  display: block;
}

@media (max-width: 425px) {
  .launches {
    width: 310px;
  }
}
