.details-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.details-title {
  font-size: 32px;
  margin-bottom: 30px;
}

.details-image,
.details-content {
  margin: 0 30px;
}

.details-youtube {
  margin: 0 auto;
  display: block;
}

button {
  background: transparent;
  cursor: pointer;
}

.button-back {
  max-width: 200px;
  display: block;
  margin: 25px auto;
}
