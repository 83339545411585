.header {
  max-width: 1230px;
  width: 90%;
  margin: 50px auto 120px;
  display: flex;
  justify-content: space-between;
}

.secondary-nav .item-link {
  color: #808080;
}

.secondary-nav .item:last-child .item-link {
  margin-right: 0;
}

.item-link.active {
  color: #33ff33;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    margin: 30px auto;
  }

  .header .logo {
    margin-bottom: 20px;
  }

  .header .main-nav {
    margin-bottom: 20px;
  }
}
